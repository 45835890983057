import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/App.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CodeSample = makeShortcode("CodeSample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to use this`}</h1>
    <ol>
      <li parentName="ol">{`Upload your template image`}</li>
      <li parentName="ol">{`Configure title and tagline`}</li>
      <li parentName="ol">{`Use in your app`}</li>
    </ol>
    <CodeSample mdxType="CodeSample" />
    <h2>{`React`}</h2>
    <p>{`First, install `}<inlineCode parentName="p">{`react-helmet`}</inlineCode>{`. If you're using Gatsby, install `}<inlineCode parentName="p">{`gatsby-plugin-react-helmet`}</inlineCode>{` as well.`}</p>
    <pre {...{
      "className": "language-jsx"
    }}><code parentName="pre" {...{
        "className": "language-jsx"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`React`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'react'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`getShareImage`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'@jlengstorf/get-share-image'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`App`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`frontmatter`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`title`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` description`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` tagline`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` frontmatter`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` shareImage `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getShareImage`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    title`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    tagline`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Helmet`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`title`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
        `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`meta`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation attr-equals"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`twitter:image`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`content`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`shareImage`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Helmet`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
  )
}
`}</span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      